<template>
    <!--<div v-file-upload-btn="dataObject" > -->

    <span @click="openFileUpload" v-bind="$attrs" data-grid-ignore-edit>
        <template v-if="!showDropZone && customBtnMarkup">
            <slot>{{ text }}</slot>
        </template>
        <template v-if="!showDropZone && !customBtnMarkup">
            <slot>
                <button :class="btnClass" :disabled="disabled || computedDisabled" :title="getTitle()">
                    <div class="spinner-border spinner-border-sm me-1" title="$t('Uploading...')" role="status"
                        v-if="showSpinner"></div>
                    {{ text }}
                </button>
            </slot>
        </template>
        <div v-if="showDropZone" class="position-relative" @dragleave="onLeave" @dragenter="onEnter" @dragover="onOver"
            @drop.prevent="onDrop">
            <slot>{{ $t("Drop files here") }}</slot>
        </div>
        <input v-if="disableMultiple" v-bind="$attrs" type="file" :disabled="uploading" ref="fileInput" class="d-none"
            @change="fileInputChange" />
        <input v-else v-bind="$attrs" type="file" :disabled="uploading" ref="fileInput" class="d-none"
            @change="fileInputChange" multiple />
        <Progress :fileUpload="dataObject ? dataObject.fileUpload : fileUploadControl" :progress="progress"
            v-if="!hideProgressBar" ref="progressModal"></Progress>
    </span>
</template>

<script setup>
import './DataObject.FileUpload.ts';
import FileUploadControl from './FileUploadControl.ts';
import { ref, useAttrs, computed } from 'vue';
import Progress from './components.FileUploadProgress.vue';


const fileInput = ref(null);
const progressModal = ref(null);

const props = defineProps({
    /** Dataobject that will be used when uploading files */
    dataObject: {
        required: false,
    },
    row: {
        required: false,
    },
    /** Specifies if the FileUpload should disable the default upload button
     *  Only be used if no template is added to the default slot
     */
    disabled: {
        type: Boolean,
        required: false,
        default: false
    },
    allowedFormats: {
        type: Array,
        required: false,
        default: null
    },
    beforeUpload: {
        type: Function,
        required: false,
        default: null
    },
    text: {
        type: String,
        required: false,
        default: "Upload"
    },
    hideLoading: {
        type: Boolean,
        required: false,
        default: false
    },
    hideProgressBar: {
        type: Boolean,
        required: false,
        default: false
    },
    showDropZone: {
        type: Boolean,
        required: false,
        default: false
    },
    primKey: {
        type: String,
        required: false,
        default: null
    },
    btnClass: {
        type: String,
        required: false,
        default: "btn btn-primary btn-sm"
    },
    customBtnMarkup: {
        type: Boolean,
        required: false,
        default: false
    },
    routeUrl: {
        type: String,
        required: false,
    },
    disableChunks: {
        type: Boolean,
        required: false,
        default: false
    },
    disableMultiple: {
        type: Boolean,
        default: (rawProps) => rawProps.row != null
    },
    skipRecordCreate: {
        type: Boolean,
        default: false
    }
});

let fileUploadControl = null;

if (!props.dataObject) {
    fileUploadControl = new FileUploadControl({
        url: props.routeUrl,
    })
}

let classList = "btn btn-primary btn-sm";
const emit = defineEmits(['uploaded', 'error']);
const attrs = useAttrs();
const progress = ref({
    total: 0,
    progress: 0

})
if (attrs.hasOwnProperty("class")) {
    classList = attrs['class'];
}

const uploadCompleted = (pData) => {
    if (progressModal.value && progress.value && !progress.value.error) {
        setTimeout(() => {
            console.log("Closing modal");
            progressModal.value?.modal.hide();
        }, 1000)

    }

    if (fileInput.value) fileInput.value.value = "";

    emit('uploaded', pData);
}
const uploadError = () => {
    setTimeout(() => {
        progressModal.value?.modal.hide();
    }, 60)
    if (fileInput.value) fileInput.value.value = "";

    emit('error');
}

const fileUploadProgress = (pProgress) => {
    progress.value = pProgress;

    if (progressModal.value && !pProgress.completed) {

        progressModal.value.modal.show();
    }

}

const uploadCancelled = () => {
    if (progressModal.value && progress.value && !progress.value.error) {
        setTimeout(() => {
            console.log("Closing modal");
            progressModal.value?.modal.hide();
        }, 1000)
    }
    props.dataObject.fileUpload.uploading = false;
    fileInput.value.value = "";
}


const openFileUpload = () => {
    if (props.disabled || computedDisabled.value) {
        return;
    }

    fileInput.value.click();
}
const fileInputChange = () => {
    const vOptions = getOptions();
    vOptions.files = fileInput.value.files;
    if (props.row) {
        const options = { PrimKey: props.row.PrimKey, index: props.row.index };
        if (options.PrimKey == null) { delete options.PrimKey; }
        props.dataObject.fileUpload.upload(vOptions, options,props.allowedFormats).then(data => {
            if (!data) {
                uploadCancelled();
            }
        }).catch(ex => {
            uploadError();
        });;
    } else if (props.dataObject) {

        props.dataObject.fileUpload.upload(vOptions, props.primKey ? { PrimKey: props.primKey } : null,props.allowedFormats).then(data => {
            if (!data) {
                uploadCancelled();
            }
        }).catch(ex => {
            uploadError();
        });
    } else {
        if (fileUploadControl !== null) {
            fileUploadControl.upload(vOptions).then(data => {
                if (!data) {
                    uploadCancelled();
                }
            }).catch(ex => {
                uploadError();
            });
        }
    }
}


const getOptions = () => {
    if (props.dataObject) {
        if (props.skipRecordCreate) {
            props.dataObject.fileUpload.skipRecordCreate = true;
        }
        if (props.routeUrl) {
            props.dataObject.fileUpload.url = props.routeUrl;
        }
        if (props.disableChunks) {
            props.dataObject.fileUpload.useChunks = false;
        }
        return {
            beforeUpload: props.beforeUpload,
            onProgress: fileUploadProgress,
            onCompleted: uploadCompleted,
            onError: uploadError
        }
    } else if (fileUploadControl !== null) {
        if (props.disableChunks) {
            fileUploadControl.useChunks = false;
        }
        if (props.routeUrl) {
            fileUploadControl.url = props.routeUrl;
        }
        return {
            beforeUpload: props.beforeUpload,
            onProgress: fileUploadProgress,
            onCompleted: uploadCompleted,
            onError: uploadError
        }
    }
}

const onDrop = (e) => {
    const vOptions = getOptions();
    vOptions.files = e.dataTransfer.files;

    if (props.row) {
        props.dataObject.fileUpload.upload(vOptions, { PrimKey: props.row.PrimKey, index: props.row.index },props.allowedFormats).then(data => {
            if (!data) {
                uploadCancelled();
            }
        });
    } else if (props.dataObject) {
        props.dataObject.fileUpload.upload(vOptions, props.primKey ? { PrimKey: props.primKey } : null,props.allowedFormats).then(data => {
            if (!data) {
                uploadCancelled();
            }
        });
    } else {
        if (fileUploadControl !== null) {
            fileUploadControl.upload(vOptions).then(data => {
                if (!data) {
                    uploadCancelled();
                }
            }).catch(ex => {
                uploadError();
            });
        }
    }


    e.target.classList.remove("dropzone");
    e.preventDefault();
}
const onOver = (e) => {
    e.preventDefault();
}
const onEnter = (e) => {
    e.target.classList.add("dropzone");
    e.preventDefault();
}
const onLeave = (e) => {
    e.target.classList.remove("dropzone");
    e.preventDefault();
}

function getTitle() {
    if (props.dataObject) {
        if (!props.dataObject.allowInsert || !props.dataObject.allowUpdate) {
            return 'Upload is disabled...'
        }
    }
    return 'Upload a file...'
}

const computedDisabled = computed(() => {
    if (props.dataObject) {
        if (props.row) {
            if (props.row.isNewRecord) {
                if (!props.dataObject.allowInsert) return true;
            } else {
                if (!props.dataObject.allowUpdate) return true;
            }

            return false;
        }

        if (props.dataObject.fileUpload.uploading == true) return true;
        if (!props.dataObject.allowInsert) return true;
        return false;
    } else {
        if (fileUploadControl !== null) {
            if (fileUploadControl.uploading == true) return true;
            return false;
        }
    }
})

const showSpinner = computed(() => {
    if (props.dataObject) {
        if (props.dataObject.fileUpload.uploading && !props.hideLoading) return true;
        return false;
    } else {
        if (fileUploadControl !== null) {
            if (fileUploadControl.uploading && !props.hideLoading) return true;
            return false;
        }
    }
})

const uploading = computed(() => {
    if (props.dataObject) {
        if (props.dataObject.fileUpload.uploading) return true;
        return false;
    } else {
        if (fileUploadControl !== null) {
            if (fileUploadControl.uploading) return true;
            return false;
        }
    }
})

defineExpose({ click: openFileUpload, uploadCancelled });

</script>

<style>
.dropzone:after {
    content: "Drop files here...";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    inset: 0;
    background-color: rgba(255, 255, 255, .8);
    border: 'dashed grey 4px';
    z-index: 1000;
}
</style>